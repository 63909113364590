<section id="absorption" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>Vstřebávání</h1>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Z vitamínu je vitamin C jedním z hlavních "spojenců" našeho
            imunitního systému. Zbytečně jím plýtváme?! Odpověď je ano!
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>vážné omezení absorbce</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>
            Od objevu vitaminu C bylo provedeno několik studií, aby se zjistilo,
            jak účinně jej tělo vstřebává.
          </h3>
          <h3>Na základě výzkumu:</h3>
          <ul>
            <li>
              Protože je vitamin C rozpustný ve vodě, může si jej tělo ukládat
              jen v omezené míře.
            </li>
            <li>
              Čím vyšší dávku do svého těla dostáváme, tím méně ji můžeme
              využít.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <img
            src="/assets/images/pages/absorption/felszivodas.png"
            alt="Liposomálna"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Část vitaminu C se vstřebává v ústní sliznici a žaludku, ale
            významné množství v tenkém střevě.
          </p>
          <p>
            Vitamin C má tolik času na vstřebávání, kolik prochází trávicí
            soustavou a vylučuje se.
          </p>
          <p>
            Dobu vstřebávání vitaminu C v trávícím traktu ovlivňuje zažívací
            systém, doba příjmu a různý metabolismus člověka. Proto se účelně
            podává v časových intervalech.
          </p>
          <p>Doba vitaminu v místě výskytu:</p>
          <ul>
            <li>v žaludku po dobu 0,5 - 3 hodin</li>
            <li>v tenkém střevě 6-8 hodin po požití</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <img src="todo" alt="" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>Co se stane s nepoužitým vitaminem C?</h3>
          <p>
            Neabsorbovaný vitamin C vstupuje do tlustého střeva odkud se
            vylučuje stolicí. Ukazuje se, že při užívání vyšších dávek
            obyčejného vitaminu C, se jej daleko více vyloučí a naše tělo bude
            po vitaminu stále hladovět.
          </p>
          <h3>Co znamená aktivní a pasivní doprava?</h3>
          <p>
            Vitamin C může být absorbován aktivním nebo pasivním transportem v
            jakékoli fázi trávicího sytému.
          </p>
          <p>
            <strong>Aktivní transport:</strong> účinné látky vstupují do krve
            sodíkovými kanály a transportéry glukózy. Propustnost a výkon
            sodíkových kanálů jsou omezené. Energeticky náročný proces.
          </p>
          <p>
            <strong>Pasivní transport:</strong> účinné látky vstupují do
            krevního oběhu membránami buněk střevní stěny. To nevyžaduje
            energetické investice ze strany organismu.
          </p>
          <img src="/assets/images/pages/absorption/abs1.png" alt="" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="content-title">
          <img src="/assets/images/pages/absorption/feltoltodes.jpeg" alt="" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>Proč je větší část klasického vitaminu C vyloučena z těla?</h3>
          <p>
            <strong
              >Některé molekuly vitaminu C jsou po požití vstřebávány v žaludku
              aktivními transportními kanály, které se zde nachází.</strong
            >
          </p>
          <p>
            Jakmile se kanál zaplní, není schopen dodávat více léků do krevního
            řečiště, takže volné molekuly vitaminu C nadále cestují s jídlem
            gastrointestinálním traktem do tenkého střeva.
          </p>
          <p>
            Počet glukózových a sodákových kanálů je omezený. Když molekula
            vitaminu C narazí na volný transportér na střevní stěně, vstřebá se.
            Pokud se transportér nasytí, neuvolní více účinných látek a zbytek
            se z těla vyloučí stolicí.
          </p>
          <p>
            To je důvod, proč se významná část požitého klasického vitaminu C
            vylučuje z těla, aniž by byla absorbována a využita.
          </p>
          <img src="/assets/images/pages/absorption/abs2.png" alt="" />
          <h3>JAK SE LIPOZOMÁLNÍ VITAMIN C VSTŘEBÁVÁ?</h3>
          <p>
            <strong
              >Některé molekuly vitaminu C uzavřené v lipozomech přenáší svůj
              obsah v žaludku jejich vazbou na buňky membránovou fúzí. Tato fůze
              je okamžitý, měřitelný proces, který rychle zvyšuje hladinu
              vitaminu C a zpřístupňuje jej tělu.</strong
            >
          </p>
          <img src="/assets/images/pages/absorption/abs3.png" alt="" />
          <p>
            Většina vitamínu C zapouzdřeného v lipozomech putuje ze žaludku přes
            trávicí trakt do tenkého střeva a po celé jeho délce se transportuje
            kontinuálně ve spojení s buňkami. Jako lipozom postupuje, vstřebává
            se a uvolňuje svůj obsah do buňky, čímž zajišťuje dlouhodobou a
            kontinuální absorpci. Lipozomální roztok nám tedy poskytuje vitamin
            C ve větších množstvích, nepřetržitě a v kratší době.
          </p>
          <h3>CO ZNAMENÁ POSTUPNÉ UVOLŇOVÁNÍ?</h3>
          <p>
            Dlouhotrvající účinek ve srovnání s běžnou formou vitaminu C
            znamená, že obsah účinné látky se uvolňuje kontinuálně po delší dobu
            tím, že se dokáže postupně vstřebávat v zažívacím traktu. Cílem je
            zajistit rovnoměrnější hladinu účinné látky v krvi, čímž se zvýší
            účinnost a sníží vedlejší účinky. V případě lipozomů lze očekávat
            nejen delší uvolńování léčiva, ale v případě vitaminu C se díky
            specifickému absorpčnímu mechanismu kyseliny askorbové spotřebuje
            více lipozomálního vitaminu C, a následně se dají dosáhnout vyšší
            plazmatické hladiny vitaminu C než při běžných formách.
          </p>
          <img src="/assets/images/pages/absorption/abs4.png" alt="" />
          <h3>CO JE LIPOZOMÁLNÍ TECHNOLOGIE A CO JSOU LIPOZOMY?</h3>
          <p>
            Lipozomální technologie je jedním z nejnovějších technologických
            objevů používaných lékařskými vědci k dodávání důležitých léků do
            orgánů. Je to proto, že tato technologie umožňuje cílené doručování
            v rámci organismu.
          </p>
          <p>
            Lipozomální vitamin C není nic jiného než vitamin C a přírodní
            látky, tzv. chytrá kombinace fosfolipidů.
          </p>
          <p>
            Během výrobního procesu je vitamin C zabalen do obalu podobnému
            buněčné membráně a vytváří kuličky velikosti nano, lipozomy.
          </p>
          <p>
            Tento obal poskytuje ochrannou vrstvu pro vitamin C během trávícího
            procesu a umožňuje mu fúzovat s buňkami. S pomocí průtoku krve se
            dostává kdekoliv v našem těle a umožňuje efektivní absorpci a
            využití.
          </p>
          <h3>CO ZNAMENÁ BIOVYUŽITÍ?</h3>
          <p>
            Biologická dostupnost je rychlost a doba, jakou s účinná složka
            uvolňuje z přípravku a je dostupná v krevním řečišti. Data jsou
            vnesena do grafu a potom jsou porovnány oblasti pod křivkami.
          </p>
          <p>
            Biologická dostupnost lipozomálního vitaminu C je dvakrát větší než
            u běžných vitaminu C. To znamená, že za jednotku času může být
            vstřebáno větší množství, může zůstat v krevním řečišti po delší
            dobu, takže plocha pod křivkou je 2x větší než plocha tradičního
            vitaminu C.
          </p>
          <h3>TAK EFEKTIVNÍ, ŽE STAČÍ NIŽŠÍ DÁVKA!</h3>
          <p>
            Další výhodou vynikajícího využití je, že dávku lze snížit, protože
            účinná látka se dostane tam, kde skutečně potřebuje pracovat. Již
            jedna kapsle Novo C <sup>&reg;</sup>300 mg denně přispívá k: účinné
            podpoře imunitního systému, snížení únavy a vyčerpání, zdraví
            pokožky, dásní, kostí a chrupavek.
          </p>
          <h3>MALÉ, ALE OSVĚDČENÉ A VELMI SILNÉ!</h3>
          <p>
            Klinická studie z roku 2015 potvrdila, že Novo C
            <sup>&reg;</sup>plus je absorbován ve střevech a vstupuje do oběhu
            ve větší míře. Během dne je tedy tělu k dispozici větší množství
            vitaminu C, a tím se lépe přizpůsobuje zvýšeným potřebám těla.
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="row pt-5 source">
            <div class="col-md-12">
              <p><small>Zdroj:</small></p>
      
              <p>
                <sup>1.</sup>
                <small
                  >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
                  biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
                  dobrovoľníkov. SOTE 2014-2015.</small
                >
              </p>
      
              <div
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
              >
                <p style="margin: 0">
                  <sup>2.</sup>
                  <small>
                    <a
                      href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                      >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
                    >
                  </small>
                </p>
              </div>
              <p>
                <small
                  >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
                  C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
                  doi.org/10.1080/08982104.2019.1630642. 2018.</small
                >
              </p>
      
              <div
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
              >
                <p style="margin: 0">
                  <sup>3.</sup>
                  <small>
                    <a
                      href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                      >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
                    >
                  </small>
                </p>
              </div>
              <p>
                <small
                  >Lukawsky M. et al. New oral liposomal vitamin C formulation: properties and bioavailability. J. Lip. Res. doi.org/10.1080/08982104.2019.1630642. 2018.</small
                >
              </p>
            </div>
          </div> -->
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
