<section id="contact">
  <div class="container pt-5">
    <div class="row pt-5 mt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Kontakt</h1>
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            MÁTE OTÁZKU NEBO KOMENTÁŘ K NAŠEMU PRODUKTU? KONTAKTUJTE NÁS DŮVĚRU
            NA TĚCHTO KONTAKTECH:
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-md-8">
        <div class="content">
          <h4>Zašlete nám zprávu</h4>
          <form [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="name">jméno a příjmení*</label>
              <input
                type="text"
                name="name"
                formControlName="name"
                required=""
              />
            </div>
            <div class="form-group">
              <label for="email">EMAILOVÁ ADRESA</label>
              <input
                type="email"
                name="email"
                formControlName="email"
                required
              />
            </div>
            <div class="form-group">
              <label for="subject">Předmět zprávy*</label>
              <input type="text" name="subject" formControlName="subject" />
            </div>
            <div class="form-group">
              <label for="message">VAŠE ZPRÁVA PRO NÁS*</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                formControlName="message"
              ></textarea>
            </div>
            <div class="form-group">
              <input
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                formControlName="privacyPolicy"
              />
              <label for="privacyPolicy" class="privacyPolicy"
                >Odesláním formuláře souhlasím se
                <a
                  href="/assets/GDPR-Penta-Pharma-SRO-2021-novocSK.pdf"
                  target="_blank"
                  style="text-decoration: underline"
                  >Zásadami pro zpracování</a
                >
                osobních údajů za účelem kontaktování společnosti.</label
              >
              <br />
            </div>
            <button
              class="btn"
              type="submit"
              [disabled]="emailFormGroup.invalid || isLoadingBtn"
            >
              Odeslat
            </button>
            <p
              class="error-msg"
              *ngIf="errorMessage"
              [innerHTML]="errorMessage"
            ></p>
          </form>
        </div>
      </div>
      <div class="col-md-4">
        <div class="content pt-5">
          <!-- <h3>TELEFÓN</h3>
                    <br> -->
          <p></p>
          <h3>E-MAIL</h3>
          <p>info@novoc.cz</p>
          <!-- <h3>TITLE</h3>
                    <p></p> -->
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span>Vrátit se na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
