<section id="elder-beryy" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Bez černý</h1>
          <img
            src="/assets/images/pages/elder-berry/elder-berry.jpeg"
            alt="Bez černý"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Černý bez je jednou z nejoblíbenějších a nejúčinnějších bylin v
            přírodní medicíně již od starověku.
          </p>
        </div>
        <div class="content">
          <p>
            Indiáni využívají jeho antiseptické a protizánětlivé účinky a z jeho
            plodů připravují oční kapky a bylinný čaj pro péči o pleť.
          </p>
          <p>Bezinky jsou bohaté na antioxidanty, antokyany a flavonoidy.</p>
          <ul>
            <li>podporuje vylučovací funkci ledvin</li>
            <li>pomáhá podporovat imunitní systém</li>
            <li>
              pomáhá udržovat normální hladinu cukru v krvi jako součást
              zdravého životního stylu
            </li>
            <li>přispívá k normální hladině glukózy</li>
            <li>
              antioxidanty pomáhají chránit buňky a tkáně před oxidačním
              poškozením
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
