<section id="rosehips" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>ŠÍPKY</h1>
          <img
            src="/assets/images/pages/rosehips/rosehips.png"
            alt="rosehips"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>Šípková růže má v tradiční medicíně dlouhodobou tradici.</p>
        </div>
        <div class="content pt-4">
          <h3>
            Obsahuje mnoho druhů vitamínŮ (vitaminy A, B1, B2, P a K), ale její
            nejdůležitější účinnou látkou je přírodní vitamin C, který:
          </h3>
          <ul>
            <li>se podílí na regulaci oxidačních procesů,</li>
            <li>posiluje cévní stěny,</li>
            <li>posiluje pojivovou tkáň,</li>
            <li>
              zvyšuje obranyschopnost těla a odolnost vůči infekcím a chorobám.
            </li>
          </ul>
          <br />
          <p>
            Šípky by neměly být namočené v horké vodě, protože vitamin C je
            zničen již kolem 50°C.
          </p>
          <p>
            Ve svém jádru obsahují vitamin E, který chrání hlavně buněčné
            membrány jednotlivých buněk, a tím pomáhá inhibovat proces stárnutí.
          </p>
          <p>
            Málokdo ví, že jej můžeme nejen konzumovat ve formě čaje, sirupu a
            džemu, ale také z něj vyrábět víno. Při výrobě vína stojí za to
            použít neoplodněné čerstvé ovoce, takže vitamin E lze využít i v
            těle. Připravované víno slouží jako vitamínový doplněk. Má mírné
            protizánětlivé a močopudné účinky.
          </p>
          <p>
            Květ ze šípku je jako růžová voda. Má osvěžující a antiseptické
            účinky. Štětiny listů jsou osvěžující a stahující jako bylinný čaj.
          </p>
          <p>
            Díky silnému antioxidačnímu účinku chrání šípek celý náš imunitní
            systém.
          </p>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
