<section id="novo-c-plus-liquid" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> plus 1000 mg</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/novo-c-plus-liquid/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> plus 1000 mg se doporučuje užívat pro:</h2>
        <ul>
          <li>správné fungování imunitného systému</li>
          <li>na snížení stavu únavy a vyčerpanosti</li>
          <li>normální fungování nervového systému</li>
          <li>zkrácení délky onemocnění v případě nachlazení</li>
          <li>udržení normální psychické pohody</li>
          <li>
            normální fungování metabolických procesů produkujících energii
          </li>
          <li>
            na podporu tvorby kolagenu a tím pro udržení normálního stavu a
            funkce pokožky, zubů a dásní, kostí a chrupavek, jakož i krevních
            cév
          </li>
          <li>zvýšení absorpce železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> plus 1000 mg vitaminu C je tekutý doplněk
            stravy obsahující lipozomální vitamin C.
          </p>
          <p>
            1000 mg vitaminu C v 5 ml denní dávky. Určené pro lidi se zvýšenými
            požadavky na vitamin C
          </p>
          <a [routerLink]="['/liposomalna-technologia']" class="btn">DALŠÍ</a>
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-plus/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="table-div">
          <h2>ÚČINNÁ LÁTKA A * NRV% V DOPORUČENÉ DENNÍ DÁVCE</h2>
          <table>
            <tbody>
              <tr>
                <th colspan="3">V 5 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>1000 mg</td>
                <td>1250%</td>
              </tr>
              <tr>
                <th colspan="3">V 10 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>2000 mg</td>
                <td>2500%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>*NRV%= Referenční hodnota denního příjmu pro dospělé.</p>
          <p>
            <b>Složení:</b> Čistá voda, Rostlinný glycerin, Vitamin: Vitamin C:
            L-askorbát sodný, Regulátor kyselosti: kyselina mléčná, Emulgátor:
            sójový lecitin, Vůně: přírodní pomerančové a mangové aroma.<br />Bez
            cukru, bez laktózy, bezlepkový, bez konzervantů, nealkoholický, pro
            vegetariany.
          </p>
          <a [routerLink]="['/informacie-o-nakupe']" class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus-liquid/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-plus-liquid/novo-c-plus-1000mg-title.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>INFORMACE O PRODUKTU:</h2>
        <p>
          <strong>Novo C<sup>&reg;</sup> plus 1000 mg zaručuje:</strong>
        </p>
        <ul>
          <li>obsah výlučně geneticky nemodifikovaných složek,</li>
          <li>přírodní barvivo získané z rajčatového extraktu (lykopen),</li>
          <li>vyrobeno bez konzervačních látek.</li>
        </ul>
        <p>
          <strong
            >Neobsahuje žádné zbytečné přísady, které by zatěžovaly
            organismus.</strong
          >
        </p>
        <p>
          Moderní výrobní proces produktu odpovídá požadavkům správné výrobní
          praxe (GMP).
        </p>

        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-alkoholmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-vegan.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>DÁVKOVÁNÍ:</h2>
        <p>
          <strong
            >Pro dospělé se může užívat 1 - 2 krát denně, odměřené po značku 5
            ml (1 000 mg vitaminu C) v dodávaném dávkovači, kdykoli během dne
            bez ředění nebo smíchat s vodou nebo džusem.
          </strong>
        </p>
        <h2>SKLADOVÁNÍ:</h2>
        <p>
          <strong
            >Skladujte na chladném a suchém místě chráněném před sálavým teplem
            a přímým slunečním zářením. Po otevření lze spotřebovat do 120 dnů.
            Nepřekračujte doporučenou spotřebu! Doplněk výživy nenahrazuje
            vyváženou smíšenou stravu a zdravý životní styl. Uchovávejte výrobek
            mimo dosah dětí! Výrobek se vyrábí v certifikovaném výrobním závodě,
            který splňuje výrobní požadavky EU.
          </strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE NOVO C<sup>&reg;</sup> PLUS 1000 MG SPECIÁLNÍ?</h2>
        <p>
          Novo C<sup>&reg;</sup> plus 1000 mg doplněk výživy obsahuje vitamín C
          v lipozomální formě. Díky inovativní technologii je vitamín C obklopen
          přírodní fosfolipidovou vrstvou, která také vytváří membrány lidských
          buněk. Toto ochranné pouzdro umožňuje vitamínu C snáze vstoupit do
          buněk. V Novo C<sup>&reg;</sup> plus 1000 mg lipozomální tekutiny je
          vitamín C velmi dobře využit a poskytuje tělu vysoké hladiny vitamínu
          C. Vitamin C přispívá k:
        </p>
        <ul>
          <li>pro normální fungování imunitního systému,</li>
          <li>chránit buňky před oxidačním stresem,</li>
          <li>ke snížení únavy a vyčerpanosti,</li>
          <li>pro normální fungování nervového systému,</li>
          <li>pro normální metabolické procesy produkující energii.</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JSOU LIPOZOMY?</h2>
        <p>
          Lipozomální vitamIn C není nic jiného než vitamIn C a přírodní látky,
          tzv. chytrá kombinace fosfolipidů. Během výrobního procesu je vitamin
          C zabalen do obalu podobnému buněčné membráně a vytváří kuličky a
          lipozomy velikosti nano. Tento obal poskytuje ochrannou vrstvu pro
          vitamin C během trávicího procesu a umožňuje mu fúzi s buňkami. Pomocí
          průtoku krve se dostává kdekoli do těla, což umožňuje jeho efektivní
          vstřebávání a využití v organismu.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          JAK SE ABSORBUJE LIPOZOMÁLNÍ VITAMIN C Z NOVO C<sup>&reg;</sup> PLUS
          1000 MG?
        </h2>
        <p>
          Měkká želatinová kapsle s vitaminem C se rozpouští v žaludku a
          uvolňují se z ní lipozomy. Některé z nich připojením se na buňky
          žaludku pomocí membránové fúze zvyšují obsah vitaminu C v krvi a
          rychle jej dodávají tělu. Většina lipozomů se dostane do tenkého
          střeva, odkud poskytuje postupné a dlouhotrvající doplnění vitaminu C
          do orgánů a systémů. Lipozomální roztok nám tedy poskytuje vitamin C
          ve větších množstvích, nepřetržitě a v kratší době.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">
            Dostupné v lékárnách. Balení a doporučená maloobchodní cena:
          </h2>
          <table>
            <tr>
              <th>Novo C plus 1000 mg vit.C liquid 150 ml CZK</th>
              <td>443 KČ</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus-liquid/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otevřená, randomizovaná, srovnávací studie k určení biologické
            dostupnosti doplňku vitaminu C Novo C<sup>&reg;</sup> plus 10000 mg
            C u zdravých dobrovolníků. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. Nová perorální lipozomální forma vitaminu C:
            vlastnosti a biologická dostupnost. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
