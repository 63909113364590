<section id="c-vitamin" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>VITAMIN C</h1>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Z vitamínů je vitamin C jedním z hlavních "spojenců" našeho
            imunitního systému. Zbytečně plýtváme?! Odpověď je ano!
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>Velký "problém vitaminu C" - OMEZENÉ VSTŘEBÁVÁNÍ</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Od objevu vitaminu C bylo provedeno několik studií, aby se zjistilo,
            jak účinně jej tělo vstřebává.
          </p>
          <p>
            Podle výzkumu: Jelikož je vitamin C rozpustný ve vodě, tělo si jej
            může ukládat jen v omezené míře. Čím vyšší dávku do těla dostáváme,
            tím méně ji můžeme využít.
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>KDE SE VITAMIN C VSTŘEBÁVÁ?</h2>
          <img
            src="/assets/images/pages/c-vitamin/c-vitamin.png"
            alt="c-vitamin"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Část vitaminu C se vstřebává v ústní sliznici a žaludku, ale
            významné množství v tenkém střevě.
          </p>
          <p>
            Vitamin C má tolik času na vstřebávání, kolik prochází trávicí
            soustavou a vylučuje se.
          </p>
          <p>
            Dobu vstřebávání vitaminu C v trávícím traktu ovlivňuje zažívací
            systém, doba příjmu a různý metabolismus člověka. Proto se účelně
            podává v časových intervalech.
          </p>
          <h4>Doba vitaminu v místě výskytu:</h4>
          <ul>
            <li>v žaludku po dobu 0,5 - 3 hodin</li>
            <li>v tenkém střevě 6-8 hodin po požití</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>Co se stane s nepoužitým vitaminem C?</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Neabsorbovaný vitamin C vstupuje do tlustého střeva odkud se
            vylučuje stolicí. Ukazuje se, že při užívání vyšších dávek
            obyčejného vitaminu C, se jej daleko více vyloučí a naše tělo bude
            po vitaminu stále hladovět.
          </p>
          <h4>NOVO C<sup>&reg;</sup> SE DOPORUČUJE UŽÍVAT PRO:</h4>
          <ul>
            <li>podporu fungování imunitního systému</li>
            <li>snížení míry únavy a vyčerpání</li>
            <li>normální funkci nervového systému</li>
            <li>ochranu buněk před oxidačním stresem</li>
            <li>udržení normálních psychických funkcí</li>
            <li>normální funkci energetického metabolismu</li>
            <li>
              správnou tvorbu kolagenu a tím udržení normálního stavu a funkce
              kůže, zubů, dásní, kostí, chrupavek a cév
            </li>
            <li>zvýšení vstřebávání železa</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
