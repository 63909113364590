<section id="citrus-bioflavonoid" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>CITRUSOVÉ BIOFLAVONOIDY</h1>
        <img
          src="/assets/images/pages/citrus-bioflavonoid/citrus-bioflavonoid.png"
          alt="citrus-bioflavonoid"
        />
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Bioflavonoidy nejsou vitamíny, ale směsi několika účinných látek, tj
            skupin sloučenin. Nejsou nezbytné pro lidské tělo, ale jsou to
            nesmírně užitečné sloučeniny.
          </p>
        </div>
        <div class="content pt-4">
          <p>
            Flavonoidy dodávají také žlutou a oranžovou barvu citrusových plodů
            a jasné začervenání třešní.
          </p>
          <p>
            Vědci již identifikovali více než 4000 různých druhů flavonoidů,
            které jsou rozšířené v rostlinných potravinách.
          </p>
          <p>
            Příklady jsou diosmin, hesperidin, peridin, rutin, flavony a
            flavonoly.
          </p>
          <h3>Některé účinky bioflavonoidů na organismus:</h3>
          <ul>
            <li>
              Chrání vitamin C před oxidací a podporují jeho vstřebávání v těle
            </li>
            <li>
              Snižují kapilaritu a propustnost k prevenci nebo léčbě modřin,
              otoků a krvácení z nosu
            </li>
            <li>Snižují hladinu cholesterolu</li>
            <li>V kombinaci s vitaminem C je vhodný k léčbě oparu</li>
            <li>
              Pomáhají překonávat závratě způsobené onemocněním vnitřního ucha
            </li>
            <li>Zpomalují proces stárnutí</li>
            <li>Pomáhají při produkci žluči</li>
            <li>Mají antioxidační účinek</li>
          </ul>
          <br />
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy. Nenahrazuje pestrou a vyváženou stravu a zdravý
          životní styl.</span
        >
      </div>
    </div>
  </div>
</section>
