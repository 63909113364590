<section id="novo-c-multivitamin" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> multivitamin</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-multivitamin-img"
          src="/assets/images/pages/novo-c-multivitamin/novo-c-multivitamin.webp"
          alt="Novo C multivitamin"
        />
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="table-div">
          <h2>
            MULTIVITAMIN NOVO C ®1 tobolka denně postupně dodává potřebné celého
            dne.
          </h2>
          <p>
            S přihlédnutím k nutričním potřebám maďarského lidu je složení
            přizpůsobeno maďarským potřebám.
          </p>
          <table>
            <tbody>
              <tr>
                <th colspan="6">ÚČINNÉ LÁTKY V JEDNÉ KAPSULE</th>
              </tr>
              <tr>
                <td>BETA-GLUKAN</td>
                <td>125 mg</td>
                <td>**</td>
                <td>VITAMIN B1</td>
                <td>1,1 mg</td>
                <td>100%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN B1</td>
                <td>1,1 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>HOŘČÍK</td>
                <td>120 mg</td>
                <td>32%</td>
                <td>MĚĎ</td>
                <td>1 mg</td>
                <td>100%</td>
              </tr>
              <tr class="mobile">
                <td>MĚĎ</td>
                <td>1 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>VITAMIN C</td>
                <td>80 mg</td>
                <td>100%</td>
                <td>VITAMIN A</td>
                <td>500 mg</td>
                <td>63%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN A</td>
                <td>500 mg</td>
                <td>63%</td>
              </tr>
              <tr>
                <td>Q10</td>
                <td>25 mg</td>
                <td>**</td>
                <td>VITAMIN B9</td>
                <td>200 mg</td>
                <td>100%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN B9</td>
                <td>200 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>VITAMIN B3</td>
                <td>16 mg</td>
                <td>100%</td>
                <td>JÓD</td>
                <td>100 mg</td>
                <td>67%</td>
              </tr>
              <tr class="mobile">
                <td>JÓD</td>
                <td>100 mg</td>
                <td>67%</td>
              </tr>
              <tr>
                <td>VITAMIN E</td>
                <td>12 mg</td>
                <td>100%</td>
                <td>SELEN</td>
                <td>55 mg</td>
                <td>100%</td>
              </tr>
              <tr class="mobile">
                <td>SELEN</td>
                <td>55 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>ZINEK</td>
                <td>10 mg</td>
                <td>100%</td>
                <td>MOLYBDEN</td>
                <td>50 mg</td>
                <td>100%</td>
              </tr>
              <tr class="mobile">
                <td>MOLYBDEN</td>
                <td>50 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>Železo</td>
                <td>10 mg</td>
                <td>71%</td>
                <td>VITAMIN B7</td>
                <td>30 mg</td>
                <td>60%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN B7</td>
                <td>30 mg</td>
                <td>60%</td>
              </tr>
              <tr>
                <td>VITAMIN B5</td>
                <td>6 mg</td>
                <td>100%</td>
                <td>VITAMIN D3</td>
                <td>25 (1000 IU) mg</td>
                <td>500%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN D3</td>
                <td>25 (1000 IU) mg</td>
                <td>500%</td>
              </tr>
              <tr>
                <td>MANGAN</td>
                <td>2 mg</td>
                <td>100%</td>
                <td>CHROM</td>
                <td>25 mg</td>
                <td>63%</td>
              </tr>
              <tr class="mobile">
                <td>CHROM</td>
                <td>25 mg</td>
                <td>63%</td>
              </tr>
              <tr>
                <td>VITAMIN B2</td>
                <td>1,4 mg</td>
                <td>100%</td>
                <td>K2</td>
                <td>15 mg</td>
                <td>*SO</td>
              </tr>
              <tr class="mobile">
                <td>K2</td>
                <td>15 mg</td>
                <td>*SO</td>
              </tr>
              <tr>
                <td>VITAMIN B6</td>
                <td>1,4 mg</td>
                <td>100%</td>
                <td>VITAMIN B12</td>
                <td>6 mg</td>
                <td>240%</td>
              </tr>
              <tr class="mobile">
                <td>VITAMIN B12</td>
                <td>6 mg</td>
                <td>240%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>* NRV % = % referenční hodnoty denního příjmu pro dospělé</p>
          <p>** NRV není určeno</p>
          <p>
            <b>Další složky:</b> sójový lecitin, nerafinovaný sezamový olej,
            včelí vosk, lykopen, betakaroten, želatina
          </p>
          <a [routerLink]="['/informace-o-nakupu']" class="btn"
            >INFORMACE O NÁKUPU</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-multivitamin/novo-c-multivitamin-title.webp"
          alt="Novo C multivitamin"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>VLASTNOSTI:</h2>
        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>DOPORUČENÉ DÁVKOVÁNÍ:</h2>
        <p>
          Od 12 let 1 měkkou želatinovou kapsli denně, zapít dostatečným
          množstvím tekutiny, nejlépe ráno. Nepřekračujte doporučené denní
          množství!
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PROČ JE NOVO C® MULTIVITAMIN SPECIÁLNÍ?</h2>
        <p>
          Nově C multivitamin je liposomální multivitamin s postupným
          vstřebáváním, který obsahuje 24 důležitých vitamínů, minerálů a
          stopových prvků.
        </p>
        <p>
          Díky svému lipozomálnímu vstřebávání se absorbuje efektivněji a dokáže
          lépe využít jednotlivé složky.
        </p>
        <p>
          Má opožděné vstřebávání, takže 1 kapsle denně postupně dodává potřebné
          mikroživiny po celý den.
        </p>
        <p>Obsahuje prebiotika a Q10.</p>
        <p>
          Obsahuje zvýšenou dávku vitamínu B12, který přispívá k normální tvorbě
          červených krvinek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENO Liposomální technologií.</h2>
        <p>
          Lipozomální technologie je jedním z nejnovějších technologických
          objevů používaných medicínskými výzkumníky pro dodávání důležitých
          aktivních složek do příslušných orgánů.
        </p>
        <p>Tato technologie umožňuje cílené vstřebávání v rámci organismu.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JSOU LIPOSOMY?</h2>
        <p>
          Liposomální multivitamin není nic jiného než různé vitamíny, minerály,
          stopové prvky a přírodní látky - inteligentní kombinace fosfolipidů.
        </p>
        <p>
          Během výrobního procesu jsou složky zabaleny do obalu podobného
          buněčné membráně, čímž se vytvoří nano-kuličky a lipozomy.
        </p>
        <p>
          Tento povlak poskytuje ochrannou vrstvu pro aktivní složky během
          procesu trávení a umožňuje jim splynout s buňkami. Pomocí průtoku krve
          se dostane do kterékoli části našeho těla, čímž umožňuje výborné
          vstřebávání a využití.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>JAK SE NOVO C® MULTIVITAMIN vstřebává?</h2>
        <p>
          Měkká želatinová multivitaminová tobolka se rozpouští v žaludku a
          uvolňují se liposomy. Část z nich se membránovou fúzí spojuje s
          buňkami žaludku, čímž zvyšuje hladinu vitamínů v krvi a zpřístupňuje
          je tělu rychle. Většina z nich se dostane do tenkého střeva, odkud
          zajišťují postupné, dlouhotrvající zásobování našich orgánů.
        </p>
        <p>
          Lipozomální roztok nám tedy poskytuje potřebné mikroživiny ve větších
          množstvích, nepřetržitě a kratším způsobem.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>CO JE MEMBRÁNOVÁ FÚZE?</h2>
        <p>
          Buňky a lipozom jsou ohraničeny stejnou lipidovou dvouvrstvou, která
          se navzájem přitahuje.
        </p>
        <p>
          Liposomy dodávají aktivní složku buňkám membránovou fúzí, takže
          množství dodávání není omezeno.
        </p>
        <p>
          Nemusíme se tedy bát, že většinu vitamínu C „uvidíme“ v koupelně,
          protože vitamín C se v našem těle využije a nevyloučí se močí. Takto
          může skutečně uplatnit svůj příznivý vliv na imunitní systém.
          Nezatěžuje ani trávící systém: nepocítíte například pálení žáhy či
          zvýšené nucení na močení.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="fas fa-chevron-left"></i>
          <span> Přejít na hlavní stránku </span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplněk stravy, nikoli náhrada smíšené stravy a zdravého životního
          stylu.
        </span>
      </div>
    </div>
  </div>
</section>
